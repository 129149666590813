import React from "react";
import Layout from "../components/layout";
import { PageText, PageH1 } from "./../styledcomponents/layoutstyles";

const DisclaimerPage = () => (
  <Layout title={`Ring On Deli: Disclaimer`}>
    <PageText>
      <PageH1>Disclaimer</PageH1>
      <p>This is the place for an entertaining legal disclaimer.</p>
      <p>
        <em>Ring On Deli</em> is a work of fiction. Characters, businesses,
        places, and events are either the products of the author’s imagination
        or used in a fictitious manner. Any resemblance to actual persons,
        living or dead, or actual events is purely coincidental. There are no
        real-world counterparts for any of the characters, whether heroic or
        villainous, who appear in this world of make believe. Also, the author
        has no affiliation with the esteemed Market Basket supermarkets of New
        England.
      </p>
      {/*<p>This is the place for some very entertaining legal disclaimers.</p>

      <p>
        <em>Ring On Deli</em> is a work of fiction. Characters, businesses,
        places, and events are either the products of the author’s imagination
        or used in a fictitious manner. Any resemblance to actual persons,
        living or dead, or actual events is purely coincidental. For example,
        there are no real-world counterparts to Chesley Brine Martini and
        Ernesta Martini, and the author is aware of no employee in any
        supermarket who shares the odious workplace habits of <em>The</em>{" "}
        Alfredo or Toothless Mary.
      </p>

      <p>
        Also, <em>Ring On Deli</em> is solely the author’s work and its sole
        affiliation is with the author and New Salem Books.<em> Ring On Del</em>
        i, any promotional material connected with <em>Ring On Deli</em>{" "}
        (including this website), New Salem Books, and <em>Ring On Deli</em>’s
        author are not in any way affiliated, connected, or associated with, or
        sponsored or approved by, Demoulas Super Markets, Inc., or any of its
        employees, stores, or affiliates.
      </p>

      <p>
        Finally, discerning users may notice that this website’s margin consists
        of tossed shards drawn from the “classic” weekly deals flyer put out by
        the Market Basket supermarkets. In an affectionate parody of that
        flyer’s super-bright design, old-timey flavor, and stark, insistent
        messaging—which typically focuses heavily on the “freshness” and
        affordability of that esteemed chain’s many and diverse products—we
        present those shards as a shifting kaleidoscope of food, words, and
        prices that seem almost to be flying out at you. This collage also
        contributes to the novel as a “satire of American appetites” (to borrow
        Stewart O’Nan’s description) and as a comic dramatization of the
        extraordinary abundance that is part of what American supermarkets
        represent in the book. In addition, it complements a few specific pages
        from <em>Ring On Deli</em> that similarly parody such flyers. When Ray
        inspects Bounty Bag’s weekly circular, he doesn’t mind the “hoary
        graphic design on tap,” even savors its “no-frills quality,” which
        appeals “to the Cotton Mather in him.” Unfortunately, New Salem Books
        does not sell any of the food partially depicted in the website’s
        transformative, parodic collage; if it leaves you famished, a prompt
        excursion to your nearest Market Basket is firmly recommended.
				</p>*/}
    </PageText>
  </Layout>
);

export default DisclaimerPage;
